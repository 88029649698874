<template>
  <div id="account-setting-holding" class="container">
    <div class="page-header mb-1">
      <strong>ส่วนแบ่งที่ได้รับ</strong>
    </div>

    <div class="card">
      <!-- <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <span class="nav-link" :class="[{'active':activeTab==='sharing'}]" @click="activeTab='sharing'">ส่วนแบ่งหุ้น</span>
          </li>
          <li class="nav-item">
            <span class="nav-link" :class="[{'active':activeTab==='commission'}]" @click="activeTab='commission'">คอมมิชชั่น</span>
          </li>
        </ul>
      </div> -->

      <div class="p-2">
        <table class="table table-bordered table-hover table-striped table-pp mb-0">
          <thead>
            <tr>
              <th width="100">ลำดับ</th>
              <th width="50%">หวย</th>
              <th width="50%">ส่วนแบ่ง (%)</th>
            </tr>
          </thead>
          <tbody
            v-for="group in groups"
            :key="group._id"
            v-if="group.markets.length"
          >
            <tr>
              <th colspan="3">{{ group.groupTitle }}</th>
            </tr>
            <tr v-for="(market, index) in group.markets" :key="market._id">
              <td class="text-center">{{ index+1 }}</td>
              <td class="text-left">
                <img :src="market.imageIcon" class="rounded" style="width: 40px;"> {{ market.marketTitle }}
              </td>
              <td class="text-center">{{ market.receive | amountFormat }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import _ from 'lodash'

export default {
  name: 'AccountHolding',
  data() {
    return {
      accountId: this.$store.state.account._id,
      data: null,
      activeTab: 'sharing'
    }
  },
  computed: {
    settings() {
      return this.data?.settings ?? {}
    },
    markets() {
      return this.data?.markets ?? []
    },
    groups() {
      return _.orderBy(this.data?.groups ?? [], ['sort'], ['asc'])
      .map((group)=>{

        const markets = this.markets.filter((m)=>{
          const marketAvailable = this.settings?.markets?.[m._id]?.isAvailable ?? true
          return m.groupId === group._id && marketAvailable && this.settings?.groups?.[group._id]?.isAvailable
        })
        .map((m)=>{
          return {
            ...m,
            receive: this.settings?.groups?.[group._id]?.share?.receive ?? 0
          }
        })

        return {
          ...group,
          isAvailable: this.settings?.groups?.[group._id]?.isAvailable ?? false,
          markets: _.orderBy(markets, ['sort'], ['asc'])
        }
      })
    }
  },
  methods: {
    loadData() {
      AccountSettingService.getAccountHoldings(this.accountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style lang="scss">
#account-setting-holding {

  .nav-link {
    cursor: pointer;
  }

  .table {
    thead {
      tr {
        th {
          padding: 5px;
          text-align: center;
        }
      }
    }
    tbody {
      background-color: #FFFFFF;

      tr {
        th {
          padding: 5px;
          background-color: #FFFFFF;
        }
        th:hover {
          background-color: #FFFFFF;
        }

        td {
          padding: 8px 5px;
        }
      }
    }
  }
}
</style>
